@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cormorant+Garamond:wght@300;400&family=Didact+Gothic&family=Roboto:wght@100&display=swap");
@import url("./FontStyles.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* font-family: "Cormorant Garamond", serif; */
  scroll-behavior: smooth;
}
@media screen and (max-width: 300px) {
  .main-content {
    display: none;
  }
}
@media screen and (min-width: 301px) {
  .ShowMessage {
    display: none;
  }
}
/* =============================================================================== */
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(116, 114, 114);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(48, 47, 47);
}
