.productdiv {
  padding: 1.5rem;
  margin: 1rem;
  cursor: pointer;
  overflow: hidden;
  /* background-color: aqua; */
}
@media only screen and (max-width: 810px) {
  .productdiv {
    padding: 0.5rem;
    width: 10rem;
    margin: 0rem;
  }
}
.ParaInDiv {
  text-align: center;
}
.productdiv:hover {
  border: 1px rgb(175, 169, 169) solid;
}
.productdiv .imgInDiv {
  will-change: transform;
  transition: transform 0.5s ease;
}
.productdiv:hover .imgInDiv {
  transform: scale(1.1);
  /* filter: grayscale(100); */
}
/* ======================================================  */
.GemstoneLandingDiv {
  background: url("../../Assets/Bannergemstone.jpg");
  background-size: cover;
  background-position: 50% 65%;
}
.finejewelLandingDiv {
  background: url("../../Assets/bannerimg/fjLp.jpg");
  background-size: cover;
  background-position: 100% 35%;
}
.EngagementBridalPageBannerDiv {
  background: url("../../Assets/bannerimg/e&bLp.jpg");
  background-size: cover;
  background-position: 100% 60%;
}
.GiftsPageBannerDiv {
  background: url("../../Assets/bannerimg/giftsLp.jpg");
  background-size: cover;
  background-position: 100% 60%;
}
.RingLandingDiv {
  background: url("../../Assets/bannerimg/ringsLp.jpg");
  background-size: cover;
  background-position: center;
}
.EarringLandingDiv {
  background: url("../../Assets/bannerimg/earringLp.jpg");
  background-size: cover;
  background-position: 100% 45%;
}
.BraceletLandingDiv {
  background: url("../../Assets/bannerimg/braceletLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
.BangleLandingDiv {
  background: url("../../Assets/bannerimg/marinaLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
.PendantLandingDiv {
  background: url("../../Assets/bannerimg/pendantLp.jpg");
  background-size: cover;
  background-position: 100% 60%;
}
.NeckalceLandingDiv {
  background: url("../../Assets/bannerimg/necklaceLp.jpg");
  background-size: cover;
  background-position: 100% 50%;
}

/* === ===== ======= ======= ===== ====== =====  === ====== ==== === */

.AstoriaLandingDiv {
  background: url("../../Assets/bannerimg/astoriaLp.jpg");
  background-size: cover;
  background-position: 100% 20%;
}
.MarinaLandingDiv {
  background: url("../../Assets/bannerimg/marinaLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
.SavannaLandingDiv {
  background: url("../../Assets/bannerimg/savannaLp.jpg");
  background-size: cover;
  background-position: 100% 60%;
}
.EnigmaLandingDiv {
  background: url("../../Assets/bannerimg/enigmaLp.jpg");
  background-size: cover;
  background-position: 100% 50%;
}
.PhulkariLandingDiv {
  background: url("../../Assets/bannerimg/phulkariLp.jpg");
  background-size: cover;
  background-position: 100% 55%;
}
.laVieEnRoseLandingDiv {
  background: url("../../Assets/bannerimg/lavieroseLp.jpg");
  background-size: cover;
  background-position: 100% 35%;
}
.LaFusiaRougeLandingDiv {
  background: url("../../Assets/bannerimg/lafusiarougeLp.jpg");
  background-size: cover;
  background-position: center;
}

.categoryLandingDiv {
  background: url("../../Assets/bannerimg/categoryLp.jpg");
  background-size: cover;
  background-position: center;
}

/* ===============    NOT IN USE    ===========================  */
.EngagementPageBannerDiv {
  background: url("../../Assets/bannerimg/engagementLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
.WeddingPageBannerDiv {
  background: url("../../Assets/bannerimg/weddingLp.jpg");
  background-size: cover;
  background-position: 100% 70%;
}
.WeddingGiftsPageBannerDiv {
  background: url("../../Assets/bannerimg/wgiftsLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
.ChristeningGiftsPageBannerDiv {
  background: url("../../Assets/bannerimg/cgiftsLp.jpg");
  background-size: cover;
  background-position: 100% 35%;
}
.BirthdayGiftsPageBannerDiv {
  background: url("../../Assets/bannerimg/bgiftsLp.jpg");
  background-size: cover;
  background-position: 100% 40%;
}
