/* kalam Font  */
@font-face {
  font-family: "Kalam";
  src: url("./Assets//Fonts/kalam/Kalam.ttf") format("truetype");
}
/* ======================================================================================================= */
/* Gotham Font  */
@font-face {
  font-family: "Gotham";
  src: url("./Assets//Fonts/Gotham/Gotham-Book.otf") format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: url("./Assets//Fonts/Gotham/Gotham-Bold.otf") format("truetype");
}
@font-face {
  font-family: "GothamItalic";
  src: url("./Assets//Fonts/Gotham/Gotham-BookItalic.otf") format("truetype");
}
@font-face {
  font-family: "GothamLight";
  src: url("./Assets//Fonts/Gotham/Gotham-Light.otf") format("truetype");
}
@font-face {
  font-family: "GothamThin";
  src: url("./Assets//Fonts/Gotham/Gotham-Thin.otf") format("truetype");
}
@font-face {
  font-family: "GothamMedium";
  src: url("./Assets//Fonts/Gotham/Gotham-Medium.otf") format("truetype");
}
/* ======================================================================================================= */
/* Cinzel Font  */
@font-face {
  font-family: "Cinzel";
  src: url("./Assets//Fonts/Cinzel/Cinzel-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelBold";
  src: url("./Assets//Fonts/Cinzel/Cinzel-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelMedium";
  src: url("./Assets//Fonts/Cinzel/Cinzel-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelSemiBold";
  src: url("./Assets//Fonts/Cinzel/Cinzel-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelExtraBold";
  src: url("./Assets//Fonts/Cinzel/Cinzel-ExtraBold.ttf") format("truetype");
}

/* ======================================================================================================= */
/* Martel Font  */
@font-face {
  font-family: "Martel";
  src: url("./Assets//Fonts/Martel/Martel-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MartelBold";
  src: url("./Assets//Fonts/Martel/Martel-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MartelSemiBold";
  src: url("./Assets//Fonts/Martel/Martel-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "MartelExtraBold";
  src: url("./Assets//Fonts/Martel/Martel-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "MartelLight";
  src: url("./Assets//Fonts/Martel/Martel-Light.ttf") format("truetype");
}
@font-face {
  font-family: "MartelExtraLight";
  src: url("./Assets//Fonts/Martel/Martel-ExtraLight.ttf") format("truetype");
}
